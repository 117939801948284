<template>
  <div class="adminBox">
    <NavBar title="账户管理"></NavBar>
    <div class="contentBox">
      <ListBox
        title="实名认证"
        :tips="authList[userInfo.status]"
        :tips-color="userInfo.status === 3 ? '#EE6560' : '#9EA2B5'"
        :right-icon="authRightIcon ? true : false"
        @click.native="onAuth"
      ></ListBox>
      <ListBox
        title="登录密码"
        :tips="passwordList[userInfo.isSetPassWord]"
        :right-icon="true"
        @click.native="onPassword"
      ></ListBox>
      <ListBox
        title="修改手机号"
        :tips="$formatPhone(userInfo.phone)"
        :right-icon="true"
        @click.native="onPhone"
      ></ListBox>
    </div>
    <van-dialog v-model="authDialog" close-on-click-overlay>
      <div class="authDialogBox">
        <div>
          <span>姓名</span>
          <span>{{ userInfo.cardName }}</span>
        </div>
        <div>
          <span>手机号</span>
          <span>{{ $formatPhone(userInfo.phone) }}</span>
        </div>
        <div>
          <span>国家</span>
          <span>{{ userInfo.regions }}</span>
        </div>
        <div>
          <span>证件号</span>
          <span>{{ userInfo.cardId }}</span>
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: {},
  data() {
    return {
      authDialog: false,
      authList: ['未认证', '认证中', '已认证', '已拒绝'],
      passwordList: ['设置', '修改'],
    };
  },
  // created() {},
  // mounted() {},
  methods: {
    onAuth() {
      if (this.judgeGoAuth) {
        this.$router.push('/personal/admin/auth');
      }
      if (this.userInfo.status === 2) {
        this.authDialog = true;
      }
    },
    onPassword() {
      this.$router.push('/personal/admin/updatePassword');
    },
    onPhone() {
      this.$router.push('/personal/admin/updatePhone');
    },
  },
  // watch: {},
  computed: {
    userInfo() {
      let userInfo = { ...this.$store.state.userInfo };
      if (userInfo.cardName) {
        // eslint-disable-next-line prefer-regex-literals
        let regExp = new RegExp('(?!^.).', 'g');
        userInfo.cardName = userInfo.cardName.replace(regExp, '*');
      }
      if (userInfo.cardId) {
        // eslint-disable-next-line prefer-regex-literals
        let regExp = new RegExp('(?!^.{0,1}).(?!.{0,1}$)', 'g');
        userInfo.cardId = userInfo.cardId.replace(regExp, '*');
      }
      return userInfo;
    },
    judgeGoAuth() {
      return this.userInfo.status === 0 || this.userInfo.status === 3;
    },
    authRightIcon() {
      return this.userInfo.status !== 1;
    },
  },
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.authDialogBox {
  padding: 71px 50px 31px 60px;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
    span {
      color: @intro-color;
      &:last-child {
        color: @title-color;
      }
    }
  }
}
</style>
